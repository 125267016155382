<template>
  <div class="logout" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Déconnexion</h1>
        <div class="form_div">
          <div class="form_content">

            <div v-if="isAuthenticated">

              <h2 class="text-center"> A bientôt {{ userFirstName }} {{ userLastName }} </h2>
              <b-button block variant="outline-danger" class="mt-4 btn-lg" @click="logout">
                  Se déconnecter
              </b-button>

            <div v-if="!isAuthenticated">
              <h2 class="text-center">
                Vous n'êtes pas connecté
              </h2>
              <p>
                Vous n'êtes pas connecté à votre compte
              </p>

              <b-button block variant="outline-primary" class="mt-2 btn-lg" @click="goToLogin">
                Se connecter
              </b-button>

            </div>

            <hr>

            <div class="row text-center">
              <div class="col-12">
                <contactComponent />
              </div>
            </div>

          </div>


          <div v-else>
            <h2 class="text-center">
              Vous n'êtes pas connecté
            </h2>
            <div class="row text-center">
              <div class="col-12">
                <contactComponent />
              </div>
            </div>

            <b-button
              block variant="outline-primary" class="mt-4"
              @click='goToLogin'>
              Se connecter
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactComponent from '@/components/contactComponent'
import formTitleComponent from '@/components/formTitleComponent'

export default {
  name: 'Logout',
  components: {
    contactComponent,
    formTitleComponent
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    userFirstName () {
      return this.$store.getters.getUserFirstName
    },
    userLastName () {
      return this.$store.getters.getUserLastName
    },
  },
  methods: {
   logout: function () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    },
    goToLogin () {
      this.$router.push('/login')
    }
  }
}
</script>
